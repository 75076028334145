import { assign, createMachine, send } from 'xstate';
import { CancelationSubscribedPackage } from './types';
import { EligiblePackageInfo } from '@customTypes/PackageInfo';
import { IVehicle } from '@redux/actions';
import {
  PreviewOrderResponse,
  SubscribedPackage,
} from '@cv/portal-cps-lib/subscription/subscription-management/models';
import { maxBy, map, uniq, flatMap, intersectionBy, chain, isNull } from 'lodash';
import { History } from 'history';
import { OrderParams } from '@api/queries/useOrders';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import { OfferType, PackageType } from '@cv/portal-cps-lib/subscription/subscription-management/enums';
import EligibleBundle from '@app/components-lib/manageSubscription/Packages/Bundle/EligibleBundle';
import EligiblePackage from '@app/components-lib/manageSubscription/Packages/PaidPackage/EligiblePackage';
import { getActualPrice } from '@app/components-lib';
import { SESSION_STORAGE_KEY } from '@manageSubscription/utils/constants';

const discountTypesToCountAsPaid = [OfferType.Base_discount, OfferType.PromotionalClassAVariableTerm];

enum ACTIONS {
  SET_SUBSCRIBED_PACKAGES = 'Set subscribed packages',
  SET_ELIGIBLE_PACKAGES = 'Set eligible packages',
  SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES = 'Set SUBSCRIBER_DISCOUNT eligible packages',
  SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE = 'Select SUBSCRIBER_DISCOUNT eligible package',
  NEXT_STEP = 'Next step',
  PREVIEW_ORDER = 'Preview order',
  CREATE_ORDER = 'Create order',
  CLEAR_ERROR = 'Clear error',
  SET_AGREEMENT_TYPE = 'Set agreement type',
  RESET_LAST_PAGE = 'Reset last page',
}

//the values from this enum will be displayed in the visualization
enum GUARDS {
  skipSelect = 'skipSelect',
  promotionsExist = 'promotionsExist',
  promotionSelected = 'promotionSelected',
  eligiblePackageSelected = 'eligiblePackageSelected',
  nextBestOfferEmpty = 'nextBestOfferEmpty',
  userWantsToSubscribe = 'userWantsToSubscribe',
  noEligiblePackagesExists = 'noEligiblePackagesExists',
  addedPackagesCCRequiredIsFalse = 'addedPackagesCCRequiredIsFalse',
}

export enum EVENTS {
  HANDLE_SELECT_PACKAGE = 'HANDLE_SELECT_PACKAGE',
  HANDLE_SELECT_PROMOTION = 'HANDLE_SELECT_PROMOTION',
  NEXT_STEP = 'Next step',
  PREVIOUS_STEP = 'Previous step',
  SET_ELIGIBLE_PACKAGES = 'Set eligible packages',
  SET_SUBSCRIBED_PACKAGES = 'Set subscribed packages',
  SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES = 'Set SUBSCRIBER_DISCOUNT eligible packages',
  SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE = 'Select SUBSCRIBER_DISCOUNT eligible package',
  SET_CANCEL_REASON = 'Set cancel reason',
  CLEAR_ERROR = 'Clear error',
  SET_AGREEMENT_TYPE = 'Set agreement type',
  RESET_FLOW_LAST_PAGE = 'Reset flow last page',
}

export enum CancelFlow {
  SelectPackages = 'SelectPackages',
  AllBenefits = 'Benefits',
  PackagePromotions = 'PackagePromotions',
  AllPromotions = 'AllPromotions',
  NextBestOffer = 'NextBestOffer',
  CancelPackages = 'CancelPackages',
  PreviewOrder = 'PreviewOrder',
  LastMessage = 'LastMessage',
  ReviewOrder = 'ReviewOrder',
  PaymentMethods = 'PaymentMethods',
}

export type CancelSubscriptionMachineContext = {
  step: CancelFlow;
  selectedVehicle?: IVehicle;
  subscribedPackages: CancelationSubscribedPackage[]; // * packages that user currently subscribed to
  selectedPackages: SubscribedPackage[]; // * packages that user selected to cancel
  subscriberDiscountEligiblePackages: EligiblePackageInfo[]; // * eligible packages for subscriber discount chanel
  allPromotions: EligiblePackageInfo[]; // * all promotions from subscriberDiscountEligiblePackages (that have discounts)
  selectedPackagesPromotions: EligiblePackageInfo[]; // * promotions for packages that user selected to cancel from subscriberDiscountEligiblePackages
  otherPromotions: EligiblePackageInfo[];
  nextBestOffer: EligiblePackageInfo;
  cancelReason: string;
  preview: PreviewOrderResponse;
  createOrder: (args: OrderParams) => void;
  previewOrder: (args: OrderParams) => PreviewOrderResponse;
  error: any;
  history: History;
  isCancelOnly: boolean;
  agreementType: AgreementType;
};

export const initialContext: CancelSubscriptionMachineContext = {
  step: CancelFlow.SelectPackages,
  subscribedPackages: [],
  selectedPackages: [],
  subscriberDiscountEligiblePackages: [],
  allPromotions: [],
  selectedPackagesPromotions: [],
  otherPromotions: [],
  nextBestOffer: null,
  cancelReason: '',
  createOrder: () => ({}),
  previewOrder: () => ({}),
  preview: {
    vin: '',
    subscribedPackages: [],
    vehicleId: '',
    amountTotal: '',
    amountWithoutTaxTotal: '',
    taxTotal: '',
    refundAmountTotal: null,
    refundAmountWithoutTaxTotal: null,
    refundTaxTotal: null,
    retentionPromos: [],
  },
  error: null,
  history: null,
  isCancelOnly: true,
  agreementType: '',
};

const unwindByVariants = (packages: EligiblePackageInfo[]) => {
  return packages
    .map((pkg) =>
      pkg.variants.map((variant) => ({
        ...pkg,
        variant,
      })),
    )
    .flat();
};

const separatePackagesPromos = ({
  allPromotions,
  selectedPackages,
}: {
  allPromotions: EligiblePackageInfo[];
  selectedPackages: SubscribedPackage[];
}) => {
  // * filter from promotions same packages variants that already was selected
  allPromotions = allPromotions
    .filter((promo) => selectedPackages.find((selected) => selected.packageVariantId !== promo.variant.id))
    .filter((promo) =>
      promo.variant.discounts.some((discount) => !discountTypesToCountAsPaid.includes(discount.offerType)),
    );

  const selectedPackagesPromotions = allPromotions
    .filter((promoPackage: EligiblePackageInfo) =>
      selectedPackages.find(
        (selected: SubscribedPackage) => !selected.isAutoSelected && selected.packageId === promoPackage.id,
      ),
    )
    .filter((promoPackage) =>
      promoPackage.variant.discounts.some((discount) => discount.offerType !== OfferType.Base_discount),
    );

  const otherPromotions = allPromotions
    .filter((promoPackage) => promoPackage.packageType !== PackageType.Add_on)
    .filter((promoPackage) => promoPackage.variant.discounts.some((discount) => discount.offerType === OfferType.Trial))
    .filter(
      (promoPackage: EligiblePackageInfo) =>
        !selectedPackages.find((selected: SubscribedPackage) => selected.packageId === promoPackage.id),
    );

  return [selectedPackagesPromotions, otherPromotions];
};

const findNextBestOffer = (
  packages: SubscribedPackage[],
  eligiblePackages: EligiblePackageInfo[],
  allSubscribedPackages: SubscribedPackage[],
) => {
  const sPackagesId = uniq(map(allSubscribedPackages, 'packageId'));
  const marketingNames = map(flatMap(packages, 'products'), 'marketingName');

  //* Check if any of the allSubscribedPackages is a bundle
  const hasSubscribedBundles = allSubscribedPackages.some(({ bundle }) => bundle);

  //* Check if none of the eligible packages is a bundle
  const hasNoPackageBundles = !packages.some(({ bundle }) => bundle);

  if (hasSubscribedBundles && hasNoPackageBundles) {
    //*  Keep only eligible packages thats not bundle
    eligiblePackages = eligiblePackages.filter(({ bundle }) => !bundle);
  }

  const isAllPaidPackage = packages.every((pkg) => pkg.amountWithoutTax !== '0.00');

  const isAtLeastOnePaidPackage = packages.some((pkg) => pkg.amountWithoutTax !== '0.00');

  const hasAutoSelectId = packages.some((pkg) => pkg?.autoSelectIds?.length > 0);

  if (!isAllPaidPackage && !hasAutoSelectId && !isAtLeastOnePaidPackage) {
    eligiblePackages = eligiblePackages.filter(
      ({ variants }) => variants.filter((price) => price.actualPrice === `0.00`).length,
    );
  } else if (!isAllPaidPackage && hasAutoSelectId) {
    eligiblePackages = eligiblePackages.filter(
      ({ variants }) => variants.filter((price) => price.actualPrice === '0.00').length,
    );
  }

  eligiblePackages = eligiblePackages
    .filter((p) => !sPackagesId.includes(p.id)) //* check that we wont suggest as next best offer variants of package that user already subscribed to
    .filter(
      ({ products }) =>
        chain(products).filter('overlapAllowed').map('marketingName').intersection(marketingNames).value().length, //* check that we wont suggest parts of bundle that user already subscribed to
    );

  const maxPriceItem = maxBy(packages, (p) => Number(p.listPrice));
  const mostExpensivePackage = maxBy(eligiblePackages, (p) => Number(p.variant.listPrice));

  if (!maxPriceItem) {
    return mostExpensivePackage;
  }

  const nextBestOffer = eligiblePackages.reduce((prev, curr) => {
    if (maxPriceItem.initialTermUnit !== curr.variant.initialTermUnit) {
      return prev;
    }
    return Math.abs(curr.variant.listPrice - maxPriceItem.listPrice) <
      Math.abs(prev.variant.listPrice - maxPriceItem.listPrice)
      ? curr
      : prev;
  }, mostExpensivePackage);

  return nextBestOffer;
};

const fetchOrderPreview = {
  id: 'fetchOrderPreview',
  src: async (ctx: CancelSubscriptionMachineContext) => {
    if (ctx.preview.vehicleId) {
      return ctx.preview;
    }
    const packagesToAdd = getSelectedSubscriptionPackages(ctx);

    const preview = await ctx.previewOrder({
      packagesToAdd,
      packagesToRemove: ctx.selectedPackages,
      reason: ctx.cancelReason,
      orderSummary: ctx.preview,
    });
    return preview;
  },
  onDone: {
    actions: assign({ preview: (context, event) => event.data }),
  },
  onError: {
    actions: assign({ error: (context, event) => event.data }),
  },
};

export const cancelSubscriptionMachine = createMachine<CancelSubscriptionMachineContext>(
  {
    predictableActionArguments: true,
    id: 'cancelSubscription',
    initial: 'initialize',
    context: initialContext,
    states: {
      initialize: {
        entry: assign({ step: CancelFlow.SelectPackages }),
        on: {
          [EVENTS.HANDLE_SELECT_PACKAGE]: 'handleSelectPackage',
          [EVENTS.SET_SUBSCRIBED_PACKAGES]: [
            {
              actions: [
                ACTIONS.SET_SUBSCRIBED_PACKAGES,
                send((ctx) => ({
                  type: EVENTS.HANDLE_SELECT_PACKAGE,
                  selectedPackage: ctx.subscribedPackages[0],
                })),
              ],
              cond: 'skipSelect',
            },
            { actions: ACTIONS.SET_SUBSCRIBED_PACKAGES },
          ],
        },
      },
      handleSelectPackage: {
        always: { cond: 'skipSelect', target: 'allBenefits' },
        entry: assign(({ subscribedPackages, allPromotions, subscriberDiscountEligiblePackages }, event) => {
          const { selectedPackage } = event;

          // find selected or deselected package in subscribedPackages by subscriptionPackageId
          // and mutate the subscribedPackages array
          const foundSelectedPackage = subscribedPackages.find(
            (p) => p.subscriptionPackageId === selectedPackage.subscriptionPackageId,
          );
          foundSelectedPackage && (foundSelectedPackage.isSelected = !foundSelectedPackage?.isSelected);

          // update isAutoSelected property of packages
          subscribedPackages = calculateAutoSelected(subscribedPackages);
          const selectedPackages = subscribedPackages.filter((p) => p.isSelected || p.isAutoSelected);

          const nextBestOffer = findNextBestOffer(
            selectedPackages,
            subscriberDiscountEligiblePackages,
            subscribedPackages,
          );

          const [selectedPackagesPromotions, otherPromotions] = separatePackagesPromos({
            allPromotions,
            selectedPackages,
          });

          return {
            subscribedPackages,
            selectedPackages,
            nextBestOffer,
            selectedPackagesPromotions,
            otherPromotions,
          };
        }),
        on: {
          [EVENTS.HANDLE_SELECT_PACKAGE]: 'handleSelectPackage',
          [EVENTS.NEXT_STEP]: 'allBenefits',
        },
      },
      allBenefits: {
        entry: assign({ step: CancelFlow.AllBenefits }),

        on: {
          [EVENTS.NEXT_STEP]: [
            { target: 'cancelPackages', cond: 'skipPromotionIfAddOn' },
            { target: 'packagePromotion', cond: 'promotionsExist' },
            { target: 'nextBestOffer' },
          ],
        },
      },
      packagePromotion: {
        always: [
          { target: 'cancelPackages', cond: 'skipPromotionIfAddOn' },
          { target: 'cancelPackages', cond: 'noPromotionsExist' },
        ],
        entry: assign({ step: CancelFlow.PackagePromotions }),
        on: {
          [EVENTS.HANDLE_SELECT_PROMOTION]: 'handleSelectPromotion',
          [EVENTS.NEXT_STEP]: 'cancelPackages',
        },
      },
      handleSelectPromotion: {
        always: [
          { target: 'cancelPackages', cond: 'noEligiblePackagesExists' },
          { target: 'payment', cond: 'promotionSelected' },
        ],
        entry: [
          assign(({ allPromotions, selectedPackages }, event) => {
            const { selectedPromotion } = event;
            const _selected = allPromotions.map((pkg) => {
              if (pkg.variant.id === selectedPromotion.variant.id) {
                return { ...pkg, isSelected: !pkg.isSelected };
              }
              return pkg;
            });

            const [selectedPackagesPromotions, otherPromotions] = separatePackagesPromos({
              allPromotions: _selected,
              selectedPackages,
            });

            return {
              allPromotions: _selected,
              selectedPackagesPromotions,
              otherPromotions,
            };
          }),
        ],
        on: {
          [EVENTS.NEXT_STEP]: 'cancelPackages',
        },
      },
      nextBestOffer: {
        always: [
          { target: 'cancelPackages', cond: 'noEligiblePackagesExists' },
          { target: 'cancelPackages', cond: 'nextBestOfferEmpty' },
        ],
        entry: assign({ step: CancelFlow.NextBestOffer }),
        on: {
          [EVENTS.SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE]: 'handleSelectEligible',
          [EVENTS.NEXT_STEP]: 'cancelPackages',
        },
      },
      handleSelectEligible: {
        always: { target: 'payment', cond: 'eligiblePackageSelected' },
        entry: assign(({ subscriberDiscountEligiblePackages }, event) => {
          const { eligiblePackage } = event;
          const _selected = subscriberDiscountEligiblePackages.map((pkg) => {
            if (pkg.variant.id === eligiblePackage.variant.id) {
              return { ...pkg, isSelected: !pkg.isSelected };
            }
            return pkg;
          });

          return {
            subscriberDiscountEligiblePackages: _selected,
          };
        }),
        on: {
          [EVENTS.SELECT_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGE]: 'handleSelectEligible',
          [EVENTS.NEXT_STEP]: 'cancelPackages',
        },
      },

      cancelPackages: {
        always: { target: 'payment', cond: 'userWantsToSubscribe' },
        entry: assign({ step: CancelFlow.CancelPackages }),
        invoke: fetchOrderPreview,
        on: {
          [EVENTS.SET_CANCEL_REASON]: 'handleSetCancelReason',
          [EVENTS.NEXT_STEP]: 'createOrder',
        },
      },

      handleSetCancelReason: {
        entry: assign((_, event) => {
          return { cancelReason: event.cancelReason };
        }),
        on: {
          [EVENTS.SET_CANCEL_REASON]: 'handleSetCancelReason',
          [EVENTS.NEXT_STEP]: 'createOrder',
        },
      },
      payment: {
        always: { target: 'previewOrder', cond: 'addedPackagesCCRequiredIsFalse' },
        entry: assign({ step: CancelFlow.PaymentMethods }),
        invoke: fetchOrderPreview,
        on: {
          [EVENTS.NEXT_STEP]: 'previewOrder',
        },
      },

      previewOrder: {
        entry: assign({ step: CancelFlow.PreviewOrder }),
        invoke: fetchOrderPreview,
        on: {
          [EVENTS.NEXT_STEP]: 'createOrder',
        },
      },

      createOrder: {
        invoke: {
          id: 'fetchCreateOrder',
          src: async (ctx) => {
            const packagesToAdd = getSelectedSubscriptionPackages(ctx);

            return ctx.createOrder({
              packagesToAdd,
              packagesToRemove: ctx.selectedPackages,
              reason: ctx.cancelReason,
              orderSummary: ctx.preview,
              agreementType: ctx.agreementType,
            });
          },
          onDone: {
            actions: [
              assign({
                isCancelOnly: (context) => {
                  const packagesToAdd = getSelectedSubscriptionPackages(context);
                  return packagesToAdd.length === 0;
                },
                step: CancelFlow.LastMessage,
              }),
              'resetLastPage',
            ],
          },
          onError: {
            actions: [EVENTS.CLEAR_ERROR, assign({ error: (context, event) => event.data })],
          },
        },
        on: {
          [EVENTS.NEXT_STEP]: 'createOrder',
        },
      },

      /////////////////////
      // final states -> no transitions exist from those states.
      /////////////////////
    },
    on: {
      [EVENTS.SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES]: {
        actions: ACTIONS.SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES,
      },
      [EVENTS.SET_SUBSCRIBED_PACKAGES]: { actions: [ACTIONS.SET_SUBSCRIBED_PACKAGES] },
      [EVENTS.CLEAR_ERROR]: { actions: [ACTIONS.CLEAR_ERROR] },
      [EVENTS.SET_AGREEMENT_TYPE]: { actions: [ACTIONS.SET_AGREEMENT_TYPE] },
      [EVENTS.RESET_FLOW_LAST_PAGE]: { actions: ACTIONS.RESET_LAST_PAGE },
    },
  },
  {
    actions: {
      [ACTIONS.SET_SUBSCRIBED_PACKAGES]: assign((ctx, event) => {
        if (event.isFetched && event.subscribedPackages.length === 0 && ctx.step === CancelFlow.SelectPackages) {
          ctx.history.push('/profile');
        }

        return {
          subscribedPackages: appendPackagesAutoSelectionDependencies(event.subscribedPackages),
        };
      }),

      [ACTIONS.SET_SUBSCRIBER_DISCOUNT_ELIGIBLE_PACKAGES]: assign(({ selectedPackages, subscribedPackages }, event) => {
        let { subscriberDiscountEligiblePackages } = event;

        const allPromotions = subscriberDiscountEligiblePackages.reduce(
          (acc: EligiblePackageInfo[], pkg: EligiblePackageInfo) => {
            const hasDiscount = pkg.variants
              .filter((variant) => variant.discounts.length)
              .filter(
                (discount) =>
                  discount.discounts.filter((el) => !discountTypesToCountAsPaid.includes(el.offerType)).length,
              );

            if (!hasDiscount.length) {
              return acc;
            }

            return [...acc, ...hasDiscount.map((variant) => ({ ...pkg, variant }))];
          },
          [],
        );

        const [selectedPackagesPromotions, otherPromotions] = separatePackagesPromos({
          allPromotions,
          selectedPackages,
        });

        subscriberDiscountEligiblePackages = unwindByVariants(event.subscriberDiscountEligiblePackages);

        const nextBestOffer = findNextBestOffer(
          selectedPackages,
          subscriberDiscountEligiblePackages,
          subscribedPackages,
        );

        return {
          subscriberDiscountEligiblePackages,
          allPromotions,
          selectedPackagesPromotions,
          otherPromotions,
          nextBestOffer,
        };
      }),
      [ACTIONS.CLEAR_ERROR]: assign({ error: null }),
      [ACTIONS.SET_AGREEMENT_TYPE]: assign((ctx, event) => {
        return { agreementType: event.agreementType };
      }),
      resetLastPage: () => sessionStorage.removeItem(SESSION_STORAGE_KEY.FLOW_LAST_PAGE),
    },
    guards: {
      skipSelect: (ctx, event) => event?.subscribedPackages?.length === 1 || ctx?.subscribedPackages?.length === 1,
      skipPromotionIfAddOn: (ctx) => ctx.selectedPackages.every((pkg) => pkg.packageType === PackageType.Add_on),
      promotionsExist: (ctx) => !!ctx?.selectedPackagesPromotions?.length || !!ctx?.allPromotions?.length,
      promotionSelected: (ctx) => !!ctx.allPromotions.filter(({ isSelected }) => isSelected).length,
      eligiblePackageSelected: (ctx) =>
        !!ctx.subscriberDiscountEligiblePackages.filter(({ isSelected }) => isSelected).length,
      nextBestOfferEmpty: (ctx) => !ctx.nextBestOffer,
      userWantsToSubscribe: (ctx) => {
        return Boolean(getSelectedSubscriptionPackages(ctx).length);
      },
      noEligiblePackagesExists: (ctx) => !!!ctx.subscriberDiscountEligiblePackages.length,
      noPromotionsExist: (ctx) => !!!ctx.selectedPackagesPromotions.length && !!!ctx.otherPromotions.length,
      addedPackagesCCRequiredIsFalse: (ctx) => {
        const pkgs = getSelectedSubscriptionPackages(ctx);
        return pkgs.reduce(
          (acc, p) =>
            acc && Number(p.variant.actualPrice) === 0 && p.variant.discounts.every((d) => d.ccRequired === false),
          true,
        );
      },
    },
  },
);

function markPackageWithIdAsAutoSelected(pkgs: CancelationSubscribedPackage[], id: string) {
  const pkg = pkgs.find((p) => p.subscriptionPackageId === id);
  pkg && (pkg.isAutoSelected = true);
}

function calculateAutoSelected(pkgs: CancelationSubscribedPackage[]) {
  //first clear isAutoSelected flag
  const packages = pkgs.map((p) => ({ ...p, isAutoSelected: false } as CancelationSubscribedPackage));
  //now check the autoSelect array dependecy for every *selected* package
  //mark every package from that array as {"isAutoSelected": true}
  packages.forEach(
    (p) =>
      p.isSelected &&
      p.autoSelectIds.forEach((autoSelectedPkgId) => markPackageWithIdAsAutoSelected(packages, autoSelectedPkgId)),
  );
  return packages;
}

function appendPackagesAutoSelectionDependencies(pkgs: SubscribedPackage[]) {
  const packages = pkgs.map(
    (p: SubscribedPackage) =>
      ({
        ...p,
        autoSelectIds: [],
        isSelected: false,
        isAutoSelected: false,
      } as CancelationSubscribedPackage),
  );

  const haveProductsIntersection = (pkg: CancelationSubscribedPackage, selectedBy: CancelationSubscribedPackage) => {
    if (!selectedBy || selectedBy?.subscriptionPackageId === pkg.subscriptionPackageId) {
      return false;
    }

    const haveInCommon = intersectionBy(selectedBy.products, pkg.products, 'id').filter((p) => !p.overlapAllowed); // * if overlapAllowed is true it means that package despise having overlap by products still not part of the bundle
    return !!haveInCommon.length;
  };

  const shouldBeAutoSelectedBy = (pkg: CancelationSubscribedPackage, selectedBy: CancelationSubscribedPackage) => {
    const isTrial = selectedBy.discounts.some((discount) => discount.offerType === 'TRIAL');

    if (isTrial && selectedBy.bundle) {
      return (
        (pkg.packageId === selectedBy.packageId && pkg.subscriptionPackageId !== selectedBy.subscriptionPackageId) ||
        haveProductsIntersection(pkg, selectedBy) // * if bundle have intersection by products with other pkg it means that this package is part of bundle
      );
    }

    if (isNull(pkg.tier) && isNull(selectedBy.tier)) {
      return isTrial && selectedBy.endDate <= pkg.startDate && pkg.marketingName === selectedBy.marketingName;
    }
    return (
      (isTrial && selectedBy.endDate <= pkg.startDate) || pkg.parentSubscriptionId === selectedBy.subscriptionPackageId
    );
  };

  //calculate autoSelect dependency array for every package
  //autoSelect property of a package is an array of ids of other packages that are going to be autoSelected with it
  packages.forEach((pkg) => {
    pkg.autoSelectIds = packages.filter((p) => shouldBeAutoSelectedBy(p, pkg)).map((p) => p.subscriptionPackageId);
  });

  return packages;
}

const getSelectedSubscriptionPackages = (ctx: CancelSubscriptionMachineContext) => {
  return [
    ...ctx.subscriberDiscountEligiblePackages.filter((p) => p.isSelected),
    ...ctx.allPromotions.filter((p) => p.isSelected),
  ];
};
